import { addPropertyControls, ControlType, RenderTarget } from "framer"
import React, { useState, useRef, useEffect } from "react"
import {
    motion,
    useSpring,
    useMotionTemplate,
    transform,
    useMotionValue,
    useTransform,
} from "framer-motion"
import CursorFlame from "https://framer.com/m/cursor-flame-bkna.js@hTll7aVhV7GiMY6qvehd"

const spring = { stiffness: 400, damping: 20 }
const inputRange = [0, 1]

/**
 * Based on https://twitter.com/benjaminnathan/status/1288092942968791041
 * Benjamin den Boer + Adam Seckel
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function Flame({ scale, borderRadius, colorA, colorB }) {
    const size = 92
    const clientX = useMotionValue(0.5)
    const clientY = useMotionValue(0.5)
    const isCanvas = RenderTarget.current() === RenderTarget.canvas

    useEffect(() => {
        if (isCanvas) return
        const convertCursorPosition = (e) => {
            // This can get out of sync when resizing the window
            clientX.set(e.clientX / window.innerWidth)
            clientY.set(e.clientY / window.innerHeight)
        }

        window.addEventListener("mousemove", convertCursorPosition)

        return () => {
            window.removeEventListener("mousemove", convertCursorPosition)
        }
    }, [])

    const rotateX = useTransform(clientX, inputRange, [-40, 40])
    const rotateY = useTransform(clientY, inputRange, [40, -40])
    const rotateXSpring = useSpring(rotateX, spring)
    const rotateYSpring = useSpring(rotateY, spring)

    const x = useTransform(clientX, inputRange, [-20, 20])
    const y = useTransform(clientY, inputRange, [-20, 20])
    const xSpring = useSpring(x, spring)
    const ySpring = useSpring(y, spring)

    const shadowX = useTransform(clientX, inputRange, [8, -8])
    const shadowY = useTransform(clientY, inputRange, [24, 12])
    const shadowXSpring = useSpring(shadowX, spring)
    const shadowYSpring = useSpring(shadowY, spring)

    const filter = useMotionTemplate`drop-shadow(${shadowXSpring}px ${shadowYSpring}px 10px rgba(0, 0, 68, 0.3))`

    return (
        <motion.div
            style={{
                width: size,
                height: size,
                rotateX: rotateXSpring,
                rotateY: rotateYSpring,
                display: "flex",
                placeItems: "center",
                placeContent: "center",
                overflow: "visible",
                margin: "auto",
                transformPerspective: 1200,
            }}
            initial={{
                borderRadius,
                background: `linear-gradient(180deg, ${colorA} 0%, ${colorB} 100%)`,
            }}
            animate={{
                borderRadius,
                background: `linear-gradient(180deg, ${colorA} 0%, ${colorB} 100%)`,
            }}
        >
            <motion.div
                style={{
                    width: 80,
                    height: 80,
                    position: "absolute",
                    overflow: "visible",
                    aspectRatio: "1 / 1",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    filter: filter,
                }}
                initial={{ scale }}
                animate={{ scale }}
                transition={{ type: "spring", ...spring }}
            >
                <CursorFlame
                    scale={scale}
                    style={{
                        width: "100%",
                        height: "100%",
                    }}
                />
            </motion.div>
        </motion.div>
    )
}

addPropertyControls(Flame, {
    scale: {
        title: "Scale",
        type: ControlType.Number,
        defaultValue: 1,
        displayStepper: true,
        step: 0.1,
    },
    borderRadius: {
        title: "Radius",
        type: ControlType.Number,
        defaultValue: 24,
        displayStepper: true,
        step: 1,
    },
    colorA: {
        title: "Color A",
        type: ControlType.Color,
        defaultValue: "transparent",
    },
    colorB: {
        title: "Color B",
        type: ControlType.Color,
        defaultValue: "transparent",
    },
})
